import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Link } from 'gatsby';

const Subscribe = () => {
  const { register, handleSubmit, watch, errors, reset } = useForm();
  const [message, setMessage] = useState(null);
  const onSubmit = data => {
    console.log(data);
    addToMailchimp(data.EMAIL, { ...data })
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);

        if (result !== 'success') {
          throw msg;
        }
        setMessage(msg);
      })
      .catch(err => {
        console.log('err', err.status);
        setMessage(err);
      });
    reset();
    setTimeout(setMessage(null), 3000);
  };

  console.log(watch('EMAIL')); // watch input value by passing the name of it

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form" className="w-full md:w-2/3 mx-auto mb-8 px-4 md:px-8">
      <div className="flex flex-col ">
        <strong className="w-full">
          I&apos;m a <span className="text-sm text-red-400">*</span>
        </strong>
        <div className="usertype flex items-center justify-start w-full my-3">
          <input
            type="radio"
            name="USERTYPE"
            ref={register({ required: true })}
            className="form-radio h-5 w-5 text-gray-600"
            value="Producer / Farmer / Supplier"
            id="mce-USERTYPE-0"
          />
          <span className="ml-2 text-gray-700 ">Producer / Farmer / Supplier</span>
          </div>
          <div className="usertype flex items-center justify-start w-full my-3">
          <input
            type="radio"
            name="USERTYPE"
            ref={register({ required: true })}
            className="form-radio h-5 w-5 text-gray-600 "
            value="Restaurant / Hotel / Buyer"
            id="mce-USERTYPE-1"
          />
          <span className="ml-2 text-gray-700">Restaurant / Hotel</span>
          <br />
        </div>
        <div className="name flex items-center justify-start w-full my-3">
        <input
          type="text"
          name="BNAME"
          ref={register({ required: true })}
          className="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-primary text-base py-2 px-4 w-12/12"
          placeholder="Business Name*"
        />

        </div>
        <div className="name flex items-center justify-start w-full my-3">
          <div className="">
            <input
              type="text"
              name="FNAME"
              ref={register({ required: true })}
              className="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-primary text-base py-2 px-4 w-11/12"
              placeholder="First Name*"
            />
          </div>
          <div className="">
            <input
              type="text"
              name="LNAME"
              ref={register()}
              className="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-primary text-base py-2 px-4 w-full"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="contact flex items-center justify-start w-full mb-3">
          <div className="">
          <input
            type="email"
            name="EMAIL"
            ref={register({ required: true })}
            className="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-primary text-base py-2 px-4 w-11/12"
            placeholder="Work Email*"
          /></div>
          <div className=""><input
            type="tel"
            name="PHONE"
            ref={register()}
            className="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-primary text-base py-2 px-4 w-full"
            placeholder="Phone"
          /></div>
        </div>
        <div className="web flex items-center justify-start w-full mb-3">
          <div className=""><input
            type="text"
            name="WEBSITE"
            ref={register()}
            className="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-primary text-base py-2 px-4 w-11/12"
            placeholder="Website"
          /></div>
          <div className=""><input
            type="text"
            name="PRODUCT"
            ref={register()}
            className="bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-primary text-base py-2 px-4 w-full"
            placeholder="Main Products / Produce"
          /></div>
        </div>
        <div className="flex justify-start items-start my-6">
          <div style={{ position: 'absolute', left: '-5000px' }} ariaHidden="true">
            <input type="text" name="b_777447be223efb29bc31e0887_2d9a4c679a" value="" />
          </div>
          <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
            <input
              type="checkbox"
              name="AGREE"
              readOnly
              className="opacity-0 absolute"
              ref={register({ required: true })}
            />
            <svg
              className="fill-current hidden w-4 h-4 text-primary pointer-events-none"
              viewBox="0 0 20 20"
            >
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            </svg>
          </div>
          <div className="select-none">
            Agree to our <Link to="/terms">Terms &amp; Conditions</Link> and <Link to="/privacy">Privacy Policy</Link>.
            <span className="text-sm text-red-400">*</span>{' '}
          </div>
        </div>
        <div className="web flex items-center justify-start w-full mb-3">
        <input
          type="submit"
          value="Get Notified"
          id="mc-embedded-subscribe"
          className="inline-flex justify-center text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primary-lighter rounded text-lg font-bold text-center"
        />
        </div>
      </div>
      {errors.EMAIL || errors.BNAME || errors.FNAME ||errors.USERTYPE || errors.AGREE && <span className="text-xs text-red-500">*Required Field</span>}
      {message && <span className="text-xs text-red-800 font-bold">{message}</span>}

      <p className="lg:text-left text-md mt-2 text-gray-600 mb-8 w-full md:w-1/2 ">
        Want to hear from us? Sign up for our newsletter and we&apos;ll email you every time we
        release a new batch of <strong>awesomeness</strong>.
      </p>
    </form>  
  );
};

export default Subscribe;
